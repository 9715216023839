/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './SearchUtm.module.css';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { useEffect, useState } from 'react';
import { UtmCategoryName } from '../../../models/traffic';
import { ReactComponent as CrossIco } from './icon/cross.svg';

interface PopupProps {
	value: string[];
	placeholder: string;
  type: UtmCategoryName;
	result: (value: string[]) => void;
}

const SearchUtm: React.FC<PopupProps> = observer(({ type, result, value, placeholder}) => {

	const store = useStores();
	const api = useApi();

	const [selectedList, setSelectedList] = useState<string[]>(value);

	const [activeSearch, setActiveSearch] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [searched, setSearched] = useState<string[]>([]);

	useEffect(() => {
		if (search.length >= 2) {
			getUtms();
		}
	}, [search]);

	useEffect(() => {
		result(selectedList);
	}, [selectedList]);

	const getUtms = async () => {
		try {
			const {body} = await api.analytics.getUtms(type, search);
			if (body) {
				setSearched(body);
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка при получении utm');
		}
	}
	
	return (
		<div style={{display: 'flex', flexDirection: 'column', margin: 5, position: 'relative'}}>
			<p style={{marginBottom: 5, fontSize: 12}}>{placeholder}</p>
			<div 
				style={{width: '100%', maxWidth: 300, background: 'white', border: '1px solid #e2e1f4', borderRadius: 8, display: 'flex', padding: 10, height: 'fit-content', flexDirection: 'row', flexWrap: 'wrap'}}
				id='tagContainer'
			>
				{selectedList.map((itemName, j) => (
					<div style={{display: 'flex', margin: 2, height: 26}}>

						<div style={{background: '#ABB8C3', padding: 4, borderRadius: '4px 0 0 4px'}}>
							<p style={{fontSize: 13, color: 'black'}}>{itemName}</p>
						</div>

						<div 
							className={styles.tagDelete}
							onClick={() => {
								const newList = [...selectedList];
								newList.splice(j, 1);
								setSelectedList(newList);
							}}
						>
							<CrossIco className={styles.tagDeleteIco} />
						</div>

					</div>
				))}
								
				<input type="text" style={{border: 0}}
					value={search}
					onFocus={() => setActiveSearch(true)}
					onChange={(e) => {
						setSearch(e.target.value);
					}}
				/>
			</div>

			{activeSearch && searched.length > 0 && 
									<div id='tagContainer' style={{position: 'absolute', border: '1px solid #e2e1f4', background: 'white', top: 90, borderRadius: 8, padding: 10, zIndex: 1000, maxHeight: 200, overflow: 'auto', width: '100%', maxWidth: 500, margin: 5, marginTop: 0}}>
										{searched.map((searchedItem) => {
											return (
												<div 
													style={{display: 'flex', margin: 2, height: 26}}
													onClick={() => {
														const newList = [...selectedList];
														newList.push(searchedItem);
														setSelectedList(newList);
														setActiveSearch(false);
														setSearch('');
													}
													}
												>

													<div style={{background: '#ABB8C3', padding: 4, borderRadius: 4, cursor: 'pointer', border: '1px solid #e2e1f4'}}>
														<p style={{fontSize: 13, color: 'black'}}>{searchedItem}</p>
													</div>

												</div>

											);
										})}
									</div>
			}
		</div>
	);
});

export default SearchUtm;
