export type allRoles = 'user' | 'admin' | 'rop' | 'technician' | 'marketer'| 'smm' | 'mop' | 'assistant' | 'ads-manager';

export const dasboardAccessRoles = ['admin', 'assistant'];
export const opAccessRoles = ['admin', 'rop', 'technician'];
export const baseAccessRoles = ['admin'];
export const bronsAccessRoles = ['admin'];
export const trafficAccessRoles = ['admin', 'smm', 'ads-manager'];
export const dealsAccessRoles = ['admin', 'rop'];
export const conversionsAccessRoles = ['admin', 'rop'];
export const distributionAccessRoles = ['admin', 'rop', 'technician'];
export const spentsAccessRoles = ['admin', 'marketer', 'smm'];
export const financeAccessRoles= ['admin'];
export const usersAccessRoles = ['admin', 'technician'];

export const allAccessRoles = [
	{id: 'nameSelect', name: 'Выберите роль пользователя'},
	// {id: 'user', name: 'Пользователь'},
	// {id: 'admin', name: 'Админ'},
	{id: 'rop', name: 'РОП'},
	{id: 'technician', name: 'Тех. спец'},
	{id: 'marketer', name: 'Маркетолог'},
	{id: 'smm', name: 'SMM'},
	{id: 'mop', name: 'Менеджер'},
	{id: 'assistant', name: 'Ассистент'},
	{id: 'ads-manager', name: 'Менеджер рекламы'},
];