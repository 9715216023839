/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './managers.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
// import { LineChart } from '@mui/x-charts/LineChart';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { formatNumberWithSpaces } from '../../utils/numbers';
import Indicator from '../../components/UI/Indicator/Indicator';
require('moment/locale/ru');

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TooltipComparison from '../../components/UI/TooltipComparison/tooltipComparison';
import Select from '../../components/UI/Select/Select';

import { Managers as ManagersModel } from '../../models/managers';
import { Calls, ManagersTable } from '../../models/analytics';

type FormattedForChart = {
	date: string;
	value: number;
	dateLast?: string;
	valueLast?: number;
	symbol?: string;
}

const Managers: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [selectedRolesNames, setSelectedRolesNames] = useState<string[]>([
		'Консультант',
		'Консультант 2',
		'Консультант 3',
		'Консультант 0',
	]);
	const [, setSelectedOp] = useState<number>(0);

	const [managersList, setManagersList] = useState<ManagersModel[]>([]);
	const [selectedManager, setSelectedManager] = useState<ManagersModel>({
		id: 'all',
		name: 'Все менеджеры',
		post: '',
	});

	const [callsCurrentPeriod, setCallsCurrentPeriod] = useState<Calls[]>([]);
	const [callsLastPeriod, setCallsLastPeriod] = useState<Calls[]>([]);

	const [managersStats, setManagersStats] = useState<ManagersTable[]>([]);

	const diffDays = useMemo(() => {
		const startDate = moment(store.dateStore.startDate);
		const endDate = moment(store.dateStore.endDate);
		return endDate.diff(startDate, 'days') + 1;
	}, [store.dateStore.startDate, store.dateStore.endDate]);

	const totalCurrentRevenue = useMemo(
		() =>
			store.analyticsStore.revenueCurentPeriod?.reduce(
				(acc: number, item: { revenue: number }) => acc + item.revenue,
				0
			) || 0,
		[store.analyticsStore.revenueCurentPeriod]
	);

	const totalLastRevenue = useMemo(
		() =>
			store.analyticsStore.revenueLastPeriod?.reduce(
				(acc: number, item: { revenue: number }) => acc + item.revenue,
				0
			) || 0,
		[store.analyticsStore.revenueLastPeriod]
	);

	const totalCurrentIncome = useMemo(
		() =>
			store.analyticsStore.revenueCurentPeriod?.reduce(
				(acc: number, item: { income: number }) => acc + item.income,
				0
			) || 0,
		[store.analyticsStore.revenueCurentPeriod]
	);

	const totalLastIncome = useMemo(
		() =>
			store.analyticsStore.revenueLastPeriod?.reduce(
				(acc: number, item: { income: number }) => acc + item.income,
				0
			) || 0,
		[store.analyticsStore.revenueLastPeriod]
	);

	const totalCurrentSales = useMemo(
		() =>
			store.analyticsStore.revenueCurentPeriod?.reduce(
				(acc: number, item: { sales: number }) => acc + item.sales,
				0
			) || 0,
		[store.analyticsStore.revenueCurentPeriod]
	);

	const totalLastSales = useMemo(
		() =>
			store.analyticsStore.revenueLastPeriod?.reduce(
				(acc: number, item: { sales: number }) => acc + item.sales,
				0
			) || 0,
		[store.analyticsStore.revenueLastPeriod]
	);

	const formatChartData = useCallback(
		(
			currentData: any[],
			lastData: any[],
			valueKey: string,
			symbol?: string
		): FormattedForChart[] => {
			const sortedCurrentData = currentData
				?.slice()
				.sort(
					(a, b) =>
						new Date(a.date).getTime() - new Date(b.date).getTime()
				);
	
			// Создаем словарь для lastData
			const lastDataMap = lastData?.reduce((acc, item) => {
				const dateKey = moment(item.date).format('DD MMM');
				acc[dateKey] = item[valueKey];
				return acc;
			}, {} as Record<string, number>);
	
			return sortedCurrentData.map((item) => {
				const date = moment(item.date).format('DD MMM');
				const dateLast = moment(item.date)
					.subtract(diffDays, 'days')
					.format('DD MMM');
	
				const valueLast = lastDataMap ? lastDataMap[dateLast] : undefined;
	
				return {
					date,
					value: item[valueKey],
					dateLast,
					valueLast,
					symbol,
				};
			});
		},
		[diffDays]
	);
	

	const formatedRevenueCurrent = useMemo(
		() =>
			formatChartData(
				store.analyticsStore.revenueCurentPeriod || [],
				store.analyticsStore.revenueLastPeriod || [],
				'revenue'
			),
		[
			store.analyticsStore.revenueCurentPeriod,
			store.analyticsStore.revenueLastPeriod,
			formatChartData,
		]
	);

	const formatedSalesCurrent = useMemo(
		() =>
			formatChartData(
				store.analyticsStore.revenueCurentPeriod || [],
				store.analyticsStore.revenueLastPeriod || [],
				'sales',
				'шт.'
			),
		[
			store.analyticsStore.revenueCurentPeriod,
			store.analyticsStore.revenueLastPeriod,
			formatChartData,
		]
	);

	const formatedCallsCurrent = useMemo(
		() =>
			formatChartData(
				callsCurrentPeriod,
				callsLastPeriod,
				'calls',
				'звонков'
			),
		[callsCurrentPeriod, callsLastPeriod, formatChartData]
	);

	const formatedCallsDurationCurrent = useMemo(
		() =>
			formatChartData(
				callsCurrentPeriod,
				callsLastPeriod,
				'minutes',
				'мин.'
			),
		[callsCurrentPeriod, callsLastPeriod, formatChartData]
	);

	const formatedMeetingsCurrent = useMemo(
		() =>
			formatChartData(
				callsCurrentPeriod,
				callsLastPeriod,
				'meetings',
				'интервью'
			),
		[callsCurrentPeriod, callsLastPeriod, formatChartData]
	);

	const loadManagers = useCallback(async () => {
		const { body: managers } = await api.managers.getManangers(
			selectedRolesNames
		);

		if (!managers) {return;}

		setManagersList([
			{ id: 'all', name: 'Все менеджеры', post: '' },
			...managers,
		]);

		if (!managers.find((manager) => manager.id === selectedManager.id)) {
			setSelectedManager({ id: 'all', name: 'Все менеджеры', post: '' });
		}
	}, [api.managers, selectedRolesNames, selectedManager.id]);

	const loadRevenue = useCallback(async () => {
		try {
			store.notificationsStore.showPreloader();

			const responsables = selectedManager?.id !== 'all' ? [selectedManager?.id] : managersList.map((item) => item.id);

			const dataRequestCurrent = {
				start_date: store.dateStore.startDate,
				end_date: store.dateStore.endDate,
				responsables,
			};

			const dataRequestPrev = {
				start_date: store.dateStore.lastPeriodStartDate,
				end_date: store.dateStore.lastPeriodEndDate,
				responsables,
			};

			const [
				revenueCurrentResponse,
				revenuePrevResponse,
				revenueProductsResponse,
				callsCurrentResponse,
				callsPrevResponse,
				managersStatsResponse,
			] = await Promise.all([
				api.analytics.getRevenue(dataRequestCurrent),
				api.analytics.getRevenue(dataRequestPrev),
				api.analytics.getRevenueProducts(dataRequestCurrent),
				api.analytics.getCalls(dataRequestCurrent),
				api.analytics.getCalls(dataRequestPrev),
				responsables.length > 0
					? api.analytics.getManagersStats(dataRequestCurrent)
					: Promise.resolve({ body: [] }),
			]);

			store.analyticsStore.setrevenueCurentPeriod(
				revenueCurrentResponse.body || []
			);
			store.analyticsStore.setrevenueLastPeriod(
				revenuePrevResponse.body || []
			);
			store.analyticsStore.setRevenueProducts(
				revenueProductsResponse.body || []
			);
			setCallsCurrentPeriod(callsCurrentResponse.body || []);
			setCallsLastPeriod(callsPrevResponse.body || []);
			setManagersStats(
				(managersStatsResponse.body || [])
					.filter((item) => item.manager_name.trim() !== '')
					.sort((a, b) => b.revenue - a.revenue)
			);
		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке данных');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}, [
		api.analytics,
		managersList,
		selectedManager,
		store.analyticsStore,
		store.dateStore,
		store.notificationsStore,
	]);

	useEffect(() => {
		(async () => {
			try {
				await loadManagers();
			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных');
			}
		})();
	}, [selectedRolesNames, loadManagers]);

	useEffect(() => {
		(async () => {
			try {
				await loadRevenue();
			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных');
			}
		})();
	}, [
		selectedManager,
		managersList,
		store.dateStore.startDate,
		store.dateStore.endDate,
		loadRevenue,
	]);

	const selectOp = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = parseInt(e.target.value, 10);
		setSelectedOp(value);

		const rolesMap: { [key: number]: string[] } = {
			0: ['Консультант', 'Консультант 2', 'Консультант 3'],
			1: ['Консультант'],
			2: ['Консультант 2'],
			3: ['Консультант 3'],
			4: ['Консультант 0'],
		};

		setSelectedRolesNames(rolesMap[value]);
	};

	const selectManager = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selected = managersList.find((item) => item.id === e.target.value);
		setSelectedManager(
			selected || { id: 'all', name: 'Все менеджеры', post: '' }
		);
	};

	const secondsToDuration = (seconds: number) => {
		const duration = moment.duration(seconds, 'seconds');
		const hours = Math.floor(duration.asHours());
		const minutes = duration.minutes();
		const secondsNew = duration.seconds();
		return `${hours}:${minutes}:${secondsNew}`;
	};

	type SortableFields = keyof ManagersTable | 'averageCheck';

	const sortByField = (
		fieldName: SortableFields,
		isCalculated = false
	) => {
		const sortedData = [...managersStats].sort((a, b) => {
			let aValue: number = 0;
			let bValue: number = 0;
	
			if (isCalculated) {
				if (fieldName === 'averageCheck') {
					aValue = a.revenue && a.sales ? a.revenue / a.sales : 0;
					bValue = b.revenue && b.sales ? b.revenue / b.sales : 0;
				}
				// Добавьте дополнительные вычисляемые поля при необходимости
			} else {
				aValue = (a[fieldName as keyof ManagersTable] as unknown as number) || 0;
				bValue = (b[fieldName as keyof ManagersTable] as unknown as number) || 0;
			}
	
			return bValue - aValue;
		});
		setManagersStats(sortedData);
	};


	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Отдел продаж</h2>
						</div>
						<div className={styles.filters}>
							{/* @ts-ignore */}
							<Select onChange={selectOp} style={{minWidth: 170, marginRight: 10, width: 'max-content'}} listCategories={[{id: 0, name: 'Все'}, {id: 1, name: 'Отдел продаж 1'}, {id: 2, name: 'Отдел продаж 2'}, {id: 3, name: 'Отдел продаж 3'}, {id: 4, name: 'Школа продаж'}]}/>
							<Select selectedValue={selectedManager.id} onChange={selectManager} listCategories={managersList} style={{minWidth: 250, width: 'max-content'}}/>
						</div>
					</div>

				</div>

				<div style={{width: '100%'}}>

					<div style={{display: 'flex', flexWrap: 'wrap'}}>
						<Indicator 
							name='Выручка' 
							curentValue={totalCurrentRevenue}
							lastValue={totalLastRevenue} 
							format='currency' 
							symbol='$'
						/>
						
						<Indicator 
							name='Касса' 
							curentValue={totalCurrentIncome}
							lastValue={totalLastIncome} 
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='Продажи' 
							curentValue={totalCurrentSales}
							lastValue={totalLastSales} 
						/>

						<Indicator 
							name='Средний чек'
							curentValue={totalCurrentRevenue / totalCurrentSales}
							lastValue={totalLastRevenue / totalLastSales}
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='Звонков'
							curentValue={managersStats.reduce((acc, item) => acc + item.calls, 0)}
						/>

						<Indicator 
							name='Время звонков'
							curentValue={managersStats.reduce((acc, item) => acc + item.calls_duration, 0)}
							format='time'
						/>

						<Indicator 
							name='Интервью'
							curentValue={managersStats.reduce((acc, item) => acc + item.meetings, 0)}
						/>

						<Indicator 
							name='Время интервью'
							curentValue={managersStats.reduce((acc, item) => acc + item.meetings_duration, 0)}
							format='time'
						/>

						<Indicator 
							name='Создано задач'
							curentValue={managersStats.reduce((acc, item) => acc + item.created_tasks, 0)}
						/>

						<Indicator 
							name='Выполнено задач'
							curentValue={managersStats.reduce((acc, item) => acc + item.complate_tasks, 0)}
						/>

						<Indicator 
							name='Сегодня задач'
							curentValue={managersStats.reduce((acc, item) => acc + item.today_tasks, 0)}
						/>

						<Indicator 
							name='Провалено задач'
							curentValue={managersStats.reduce((acc, item) => acc + item.failed_tasks, 0)}
						/>

						<Indicator 
							name='Создано сделок'
							curentValue={managersStats.reduce((acc, item) => acc + item.created_deals, 0)}
						/>

						<Indicator 
							name='Входящих сделок'
							curentValue={managersStats.reduce((acc, item) => acc + item.income_deals, 0)}
						/>

						<Indicator 
							name='Сделок в работе'
							curentValue={managersStats.reduce((acc, item) => acc + item.in_work_deals, 0)}
						/>

						<Indicator 
							name='Реализованно сделок'
							curentValue={managersStats.reduce((acc, item) => acc + item.realized_deals, 0)}
						/>

						<Indicator 
							name='Не реализованно сделок'
							curentValue={managersStats.reduce((acc, item) => acc + item.unrealized_deals, 0)}
						/>

					</div>

					<ResponsiveContainer width={'100%'} height={400}>
						<LineChart margin={{top: 20}} data={formatedRevenueCurrent}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date"/>
							<YAxis tickFormatter={v => `${formatNumberWithSpaces(v)} $`} fontSize={12} width={70}/>
							<Tooltip content={<TooltipComparison/>}/>
							<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Выручка'/>
							<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Выручка' opacity={0.2}/>
						</LineChart>
					</ResponsiveContainer>

					<div className={styles.chartsContainer}>
						<ResponsiveContainer className={styles.chartInContainer} height={300}>
							<LineChart margin={{top: 20}} data={formatedSalesCurrent}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date"/>
								<YAxis tickFormatter={v => `${formatNumberWithSpaces(v)} шт.`} fontSize={12} width={70}/>
								<Tooltip content={<TooltipComparison/>}/>
								<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Продажи'/>
								<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Продажи' opacity={0.2}/>
							</LineChart>
						</ResponsiveContainer>

						<ResponsiveContainer className={styles.chartInContainer} height={300}>
							<LineChart margin={{top: 20}} data={formatedCallsDurationCurrent}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date"/>
								<YAxis tickFormatter={v => `${formatNumberWithSpaces(v)} мин.`} fontSize={12} width={70}/>
								<Tooltip content={<TooltipComparison/>}/>
								<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Минут'/>
								<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Минут' opacity={0.2}/>
							</LineChart>
						</ResponsiveContainer>
					</div>

					<div className={styles.chartsContainer}>
						<ResponsiveContainer className={styles.chartInContainer} height={300}>
							<LineChart margin={{top: 20}} data={formatedCallsCurrent}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date"/>
								<YAxis tickFormatter={v => `${formatNumberWithSpaces(v)} шт.`} fontSize={12} width={70}/>
								<Tooltip content={<TooltipComparison/>}/>
								<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Звонков'/>
								<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Звонков' opacity={0.2}/>
							</LineChart>
						</ResponsiveContainer>

						<ResponsiveContainer className={styles.chartInContainer} height={300}>
							<LineChart margin={{top: 20}} data={formatedMeetingsCurrent}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date"/>
								<YAxis tickFormatter={v => `${formatNumberWithSpaces(v)} шт.`} fontSize={12} width={70}/>
								<Tooltip content={<TooltipComparison/>}/>
								<Line type="monotone" dataKey={'value'} stroke="#8884d8" strokeWidth={2} name='Интервью'/>
								<Line type="monotone" dataKey={'valueLast'} stroke="#8884d8" strokeWidth={2} name='Интервью' opacity={0.2}/>
							</LineChart>
						</ResponsiveContainer>
					</div>


					<div style={{display: 'flex', flexDirection: 'column'}}>

						{managersStats.length > 0 &&
						<Paper sx={{ width: '100%', overflow: 'hidden' }} >
							<TableContainer 
								sx={{ maxHeight: 600 }}
							>
								<Table sx={{ minWidth: 320 }} stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow style={{textAlign: 'right'}}>
											<TableCell>Менеджер</TableCell>
											<TableCell onClick={() => sortByField('revenue')} style={{cursor: 'pointer'}} align="right">Выручка</TableCell>
											<TableCell onClick={() => sortByField('income')} style={{cursor: 'pointer', minWidth: 100}} align="right">Касса</TableCell>
											<TableCell onClick={() => sortByField('sales')} style={{cursor: 'pointer'}} align="right">Продаж</TableCell>
											<TableCell onClick={() => sortByField('averageCheck', true)} style={{minWidth: 100, textAlign: 'center', cursor: 'pointer'}} align="right">Ср. чек</TableCell>

											<TableCell onClick={() => sortByField('calls')} style={{cursor: 'pointer'}} align="right">Звонков</TableCell>
											<TableCell onClick={() => sortByField('calls_duration')} style={{cursor: 'pointer'}} align="right">t звонков</TableCell>
											<TableCell onClick={() => sortByField('meetings')} style={{cursor: 'pointer'}} align="right">Интервью</TableCell>
											<TableCell onClick={() => sortByField('meetings_duration')} style={{cursor: 'pointer'}} align="right">t интервью</TableCell>

											<TableCell onClick={() => sortByField('created_tasks')} style={{cursor: 'pointer'}} align="right">Созд. задач</TableCell>
											<TableCell onClick={() => sortByField('complate_tasks')} style={{cursor: 'pointer'}} align="right">✅ задач</TableCell>
											<TableCell onClick={() => sortByField('today_tasks')} style={{cursor: 'pointer'}} align="right">📆 задач</TableCell>
											<TableCell onClick={() => sortByField('failed_tasks')} style={{cursor: 'pointer'}}  align="right">❌ задач</TableCell>

											<TableCell onClick={() => sortByField('created_deals')} style={{cursor: 'pointer'}} align="right">Созд. сделок</TableCell>
											<TableCell onClick={() => sortByField('income_deals')} style={{cursor: 'pointer'}} align="right">Вход. сделок</TableCell>
											<TableCell onClick={() => sortByField('in_work_deals')} style={{cursor: 'pointer'}} align="right">В работе</TableCell>
											<TableCell onClick={() => sortByField('realized_deals')} style={{cursor: 'pointer'}} align="right">✅ сделок</TableCell>
											<TableCell onClick={() => sortByField('unrealized_deals')} style={{cursor: 'pointer'}} align="right">❌ сделок</TableCell>

										</TableRow>
									</TableHead>
									<TableBody>
										{managersStats.map((manager) => (
											<TableRow
												key={manager.manager_id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">
													{manager.manager_name}
												</TableCell>
												<TableCell align="right">{`${formatNumberWithSpaces(manager.revenue) ? formatNumberWithSpaces(manager.revenue) : 0} $`}</TableCell>
												<TableCell align="right">{`${formatNumberWithSpaces(Math.ceil(manager.income)) ? formatNumberWithSpaces(Math.ceil(manager.income)) : 0} $`}</TableCell>
												<TableCell align="right">{manager.sales}</TableCell>
												<TableCell style={{minWidth: 100, textAlign: 'center'}} align="right">{`${manager.sales > 0 && manager.revenue > 0 ? formatNumberWithSpaces(Math.ceil(manager.revenue / manager.sales)) : 0} $`}</TableCell>

												<TableCell align="right">{manager.calls}</TableCell>
												<TableCell align="right">{secondsToDuration(manager.calls_duration)}</TableCell>
												<TableCell align="right">{manager.meetings}</TableCell>
												<TableCell align="right">{secondsToDuration(manager.meetings_duration)}</TableCell>

												<TableCell align="right">{manager.created_tasks}</TableCell>
												<TableCell align="right">{manager.complate_tasks}</TableCell>
												<TableCell align="right">{manager.today_tasks}</TableCell>
												<TableCell align="right">{manager.failed_tasks}</TableCell>

												<TableCell align="right">{manager.created_deals}</TableCell>
												<TableCell align="right">{manager.income_deals}</TableCell>
												<TableCell align="right">{manager.in_work_deals}</TableCell>
												<TableCell align="right">{manager.realized_deals}</TableCell>
												<TableCell align="right">{manager.unrealized_deals}</TableCell>

											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
						}


						<TableContainer component={Paper} style={{marginTop: 20, overflowX: 'auto', margin: 5}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Продукт</TableCell>
										<TableCell align="right">Выручка</TableCell>
										<TableCell align="right">Продаж</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{store.analyticsStore.revenueProducts.map((row) => (
										<TableRow
											key={row.product_id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{row.product_name}
											</TableCell>
											<TableCell align="right">{`${formatNumberWithSpaces(row.revenue)} $`}</TableCell>
											<TableCell align="right">{row.sales}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						

					</div>


				</div>

				

			</div>

		</>
	);
});

export default Managers;