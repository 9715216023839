import FlowChart from '../../components/flow/FlowChart/FlowChart';
import styles from './maps.module.css';
import { observer } from 'mobx-react';

const Maps: React.FC = observer(() => {
	


	return (
		<>
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Карты</h2>
						</div>
					</div>

				</div>

				<FlowChart />

			
				

			</div>

		</>
	);
});

export default Maps;