/* eslint-disable @typescript-eslint/no-unused-vars */
// Импорт необходимых модулей
import React, { useState, useCallback } from 'react';
import ReactFlow, {
	addEdge,
	Background,
	Controls,
	MiniMap,
	ReactFlowProvider,
	useEdgesState,
	useNodesState,
	Connection,
	Edge,
	Node,
	NodeProps,
	NodeResizer,
	Handle, 
	Position,
} from 'reactflow';
import 'reactflow/dist/style.css';

// Кастомный компонент узла с возможностью изменения размера и редактирования текста
const ResizableEditableNode: React.FC<NodeProps> = ({ id, data, selected }) => {
	const [label, setLabel] = useState<string>(data.label || '');
	const [width, setWidth] = useState<number>(data.width || 150);
	const [height, setHeight] = useState<number>(data.height || 100);

	// Обработчик изменения текста внутри узла
	const onLabelChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setLabel(event.target.value);
		data.onChange(id, { ...data, label: event.target.value });
	};

	return (
		<>
			<NodeResizer minWidth={100} minHeight={30}/>
			<div style={{ padding: 10}}>{data.label}</div>
			<Handle type="source" position={Position.Right} style={{ background: 'blue' }} />
			<Handle type="target" position={Position.Left} style={{ background: 'red' }} />
		</>

	);
};

// Определение типов узлов
const nodeTypes = {
	resizableEditableNode: ResizableEditableNode,
};

// Главный компонент потока
const FlowChart: React.FC = () => {
	const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);

	// Обработчик соединения узлов
	const onConnect = useCallback(
		(params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)),
		[setEdges]
	);

	// Функция для добавления нового узла
	const onAddNode = () => {
		const newNode: Node = {
			id: `${nodes.length + 1}`,
			type: 'resizableEditableNode',
			position: { x: 250, y: 250 },
			style: {background: 'white'},
			data: {
				label: '',
				onChange: handleNodeDataChange,
				width: 150,
				height: 100,
			},
		};
		setNodes((nds) => nds.concat(newNode));
	};

	// Обработчик изменений данных узла
	const handleNodeDataChange = (id: string, data: Partial<Node['data']>) => {
		setNodes((nds) =>
			nds.map((node) => (node.id === id ? { ...node, data: { ...node.data, ...data } } : node))
		);
	};

	return (
		<div style={{ height: '80vh' }}>
			<button onClick={onAddNode}>Добавить блок</button>

			<ReactFlowProvider>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					nodeTypes={nodeTypes}
					fitView
				>
					<Background />
					<Controls />
					<MiniMap />
				</ReactFlow>
			</ReactFlowProvider>
		</div>
	);
};

export default FlowChart;
