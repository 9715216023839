/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react'
import Indicator from '../../../UI/Indicator/Indicator'
import { useEffect, useState } from 'react';
import TrfficStats from '../../../../api/analytics/dto/TrfficStatsResp.dto';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WebinarsRevenueResp, { SaleWeb } from '../../../../api/analytics/dto/WebinarsRevenueResp.dto';
import FbSpentUrl from '../../../../api/analytics/dto/GetFbSpentUrl.dto';
import ProductUrls from '../../../../api/analytics/dto/ProductUrls.dto';
import { formatNumberWithSpaces } from '../../../../utils/numbers';
import { TablePagination } from '@mui/material';
import { useApi } from '../../../../hooks/useApi';
import { useStores } from '../../../../hooks/useStore';
import { AppSettings } from '../../../../appSettings';
import { CountryInfo } from '../../../../models/analytics';
import FbSpentCountry from '../../../../api/analytics/dto/GetFbSpentCountry.dto';
import WebStatsResp from '../../../../api/analytics/dto/WebStatsResp.dto';
import ProductWebinars from '../../../../api/analytics/dto/ProductWebinars.dto';

import countries from 'i18n-iso-countries';
import * as ru from 'i18n-iso-countries/langs/ru.json';
import { UtmCategoryName } from '../../../../models/traffic';
import SearchUtm from '../../../UI/SearchUtm/SearchUtm';
import TrfficStatsRequest from '../../../../api/analytics/dto/TrfficStatsRequest.dto';
import { Button } from '../../../UI/Button/Button';
import FbCreatives from '../../../../api/analytics/dto/GetFbCreativesResp';
countries.registerLocale(ru);

interface StatsByProduct {
	name: string,
	spend: number,
	registration: number,
	checkins: number,
	sales: SaleWeb[]
}

interface CountryTable {
	country: string;
	spent: number;
	revenue: number;
	sales: number;
	roas: number;
}

interface CountryReplace {
	oldName: string;
	newName: string;
}

const replaceCountry = [
	{ oldName: 'Соединенные Штаты', newName: 'США' },
	{ oldName: 'Объединенные Арабские Эмираты', newName: 'ОАЭ' }
]


const FB: React.FC<{dateRevenue: [string, string]}> = observer(({dateRevenue}) => {

	const api = useApi();
	const store = useStores();

	const [totalSpent, setTotalSpent] = useState(0);
	const [trafficStats, setTrafficStats] = useState<TrfficStats>();
	const [statsByProduct, setStatsByProduct] = useState<StatsByProduct[]>([]);
	const [spendData, setSpendData] = useState<FbSpentUrl[]>([]);
	const [urrlByProduct, setUrlByProduct] = useState<ProductUrls[]>([]);

	const [pageSpend, setPageSpend] = useState(0);
	const [rowsPerPageSpend, setRowsPerPageSpend] = useState(10);

	const [countrtyFbTable, setCountryFbTable] = useState<CountryTable[]>([]);
	const [pageCountrtyFbTable, setPageCountrtyFbTable] = useState(0);
	const [rowsPerPageCountrtyFbTable, setRowsPerPageCountrtyFbTable] = useState(10);

	const [totalSpentCountry, setTotalSpentCountry] = useState(0);
	const [totalRevenueCountry, setTotalRevenueCountry] = useState(0);

	const [revenuesWebinars, setRevenuesWebinars] = useState<WebinarsRevenueResp[]>([]);

	const [webinarsStats, setWebinarsStats] = useState<WebStatsResp[]>([]);
	const [webinarsByProduct, setWebinarsByProduct] = useState<ProductWebinars[]>([]);

	const [uniqProducts, setUniqProducts] = useState<{productName: string, urls: string[], webinars: string[]}[]>([]);

	const [, setRevenuesByCountry] = useState<CountryInfo[]>([]);
	const [, setSpendByCountry] = useState<FbSpentCountry[]>([]);

	const [utmSource, setUtmSource] = useState<string[]>(['fb', 'Aminova', 'aminova']);
	const [utmMedium, setUtmMedium] = useState<string[]>([]);
	const [utmCampaign, setUtmCampaign] = useState<string[]>([]);
	const [utmTerm, setUtmTerm] = useState<string[]>([]);
	const [utmContent, setUtmContent] = useState<string[]>([]);

	const [creativesTable, setCreativesTable] = useState<FbCreatives[]>([]);
	const [pageCreativesTable, setPageCreativesTable] = useState(0);
	const [rowsPerPageCreativesTable, setRowsPerPageCreativesTable] = useState(10);

	const [sortConfigFbCreatives, setSortConfigFbCreatives] = useState<{ key: string, direction: string } | null>(null);

	const sortByKeyCreativesTable = (key: keyof FbCreatives) => {
		let direction = 'ascending';
		if (sortConfigFbCreatives && sortConfigFbCreatives.key === key && sortConfigFbCreatives.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfigFbCreatives({ key, direction });

		const sortedArray = [...creativesTable];

		sortedArray.sort((a, b) => {
			if (a[key] < b[key]) {
				return direction === 'ascending' ? -1 : 1;
			}
			if (a[key] > b[key]) {
				return direction === 'ascending' ? 1 : -1;
			}
			return 0;
		});

		setCreativesTable(sortedArray);
	};

	const handleChangePageCreativesTable = (event: unknown, newPage: number) => {
		setPageCreativesTable(newPage);
	}

	const handleChangeRowsPerPageCreativesTable = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageCreativesTable(+event.target.value);
		setPageCreativesTable(0);
	}

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();

				const {body: urlByProductResp} = await api.analytics.getUrlsForProducts();

				if (urlByProductResp) {
					setUrlByProduct(urlByProductResp);
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, []);

	useEffect(() => {
		if (countrtyFbTable) {
			let totalSpentFb = 0;
			let totalRevenue = 0;

			countrtyFbTable.forEach((item) => {
				totalSpentFb += item.spent;
				totalRevenue += item.revenue;
			});

			setTotalSpentCountry(totalSpentFb);
			setTotalRevenueCountry(totalRevenue);
		}
	}, [countrtyFbTable]);

	useEffect(() => {
		(async () => {
			try {
				store.notificationsStore.showPreloader();

				const {body} = await api.analytics.getSpendFbUrl({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
				});

				if (body) {
					setSpendData(body.sort((a, b) => b.total - a.total));

					let total = 0;
					body.forEach((item) => {
						total += item.total;
					});

					setTotalSpent(total);
				}

				const data: TrfficStatsRequest = {
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
					bron_ids: AppSettings.bronIds,
				}

				if (utmSource.length > 0) {
					data.utm_source = utmSource;
				}

				if (utmMedium.length > 0) {
					data.utm_medium = utmMedium;
				}

				if (utmCampaign.length > 0) {
					data.utm_campaign = utmCampaign;
				}

				if (utmTerm.length > 0) {
					data.utm_term = utmTerm;
				}

				if (utmContent.length > 0) {
					data.utm_content = utmContent;
				}

				const {body: trafficStatsResp} = await api.analytics.getTrafficStats(data);

				if (trafficStatsResp) {
					setTrafficStats(trafficStatsResp);
				}

				const {body: revenuesWeb} = await api.analytics.getWebinarsRevenue({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
					products: [...AppSettings.bronIds, ...AppSettings.bronIdsSsd],
					utm_source: utmSource,
				});

				if (revenuesWeb) {
					setRevenuesWebinars(revenuesWeb);
				}

				const {body: revenuesByCountryResp} = await api.analytics.getRevenueCountry({
					start_date: dateRevenue[0],
					end_date: dateRevenue[1],
					utm_sources: utmSource,
					start_date_contact_created: store.dateStore.startDate,
					end_date_contact_created: store.dateStore.endDate,
				});

				if (revenuesByCountryResp) {
					setRevenuesByCountry(replaceCountryName(revenuesByCountryResp, replaceCountry));
				}

				const {body: spendByCountryResp} = await api.analytics.getSpendFbCountry({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
				});

				if (spendByCountryResp) {
					setSpendByCountry(spendByCountryResp);
				}

				if (revenuesByCountryResp && spendByCountryResp) {
					const countryTable: CountryTable[] = [];
			
					replaceCountryName(revenuesByCountryResp, replaceCountry).forEach((revenueCountry) => {

						const spendCountry = spendByCountryResp.find((spendCount) => getCountryName(spendCount.country) === revenueCountry.country);
			
						if (spendCountry) {
							countryTable.push({
								country: revenueCountry.country,
								spent: spendCountry.total,
								revenue: revenueCountry.total,
								sales: revenueCountry.count ? revenueCountry.count : 0,
								roas: +(revenueCountry.total / spendCountry.total * 100).toFixed(2),
							});
						}
					});

					spendByCountryResp.forEach((spendCountry) => {
						const revenueCountry = revenuesByCountryResp.find((revenueCount) => revenueCount.country === getCountryName(spendCountry.country));
			
						if (!revenueCountry) {
							countryTable.push({
								country: getCountryName(spendCountry.country),
								spent: spendCountry.total,
								revenue: 0,
								sales: 0,
								roas: 0,
							});
						}
					});
			
					setCountryFbTable(countryTable.sort((a, b) => b.spent - a.spent));
				}

				const {body: webinarsStatsResp} = await api.analytics.getWebinarsStatsByName({
					start_date: store.dateStore.startDate,
					end_date: store.dateStore.endDate,
					utm_source: utmSource,
				});

				if (webinarsStatsResp) {
					setWebinarsStats(webinarsStatsResp);
				}

				const {body: getWebinarsByProductResp} = await api.analytics.getWebinarsForProducts();

				if (getWebinarsByProductResp) {
					setWebinarsByProduct(getWebinarsByProductResp);
				}

				const {body: creativesTableResp} = await api.analytics.getFbCreatives(store.dateStore.startDate, store.dateStore.endDate)

				if (creativesTableResp) {

					const filterTableCreo = creativesTableResp.filter((item) => item.ad_id !== '').sort((a, b) => b.expenses - a.expenses);

					setCreativesTable(filterTableCreo.map((item) => ({
						...item,
						roas: +(item.revenue / item.expenses * 100).toFixed(2)
					})));
				}

			} catch (error) {
				store.notificationsStore.setError('Ошибка при загрузке данных')
			} finally {
				store.notificationsStore.hidePreloader();
			}
		})();
	}, [store.dateStore.startDate, store.dateStore.endDate, dateRevenue]);

	const updateTrafficStats = async () => {
		try {
			store.notificationsStore.showPreloader();

			const data: TrfficStatsRequest = {
				start_date: store.dateStore.startDate,
				end_date: store.dateStore.endDate,
				bron_ids: AppSettings.bronIds,
			}

			if (utmSource.length > 0) {
				data.utm_source = utmSource;
			}

			if (utmMedium.length > 0) {
				data.utm_medium = utmMedium;
			}

			if (utmCampaign.length > 0) {
				data.utm_campaign = utmCampaign;
			}

			if (utmTerm.length > 0) {
				data.utm_term = utmTerm;
			}

			if (utmContent.length > 0) {
				data.utm_content = utmContent;
			}

			const {body: trafficStatsResp} = await api.analytics.getTrafficStats(data);

			if (trafficStatsResp) {
				setTrafficStats(trafficStatsResp);
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке данных')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	useEffect(() => {
		const spendByProductNew: StatsByProduct[] = [];

		uniqProducts.forEach((product) => {
			const spend = spendData.filter((item) => product.urls.includes(item.url));

			const registration = webinarsStats.filter((item) => product.webinars.includes(item.webinar_name)).reduce((acc, item) => acc + item.registration, 0);
			const checkins = webinarsStats.filter((item) => product.webinars.includes(item.webinar_name)).reduce((acc, item) => acc + item.checkins, 0);

			const websRevenue = revenuesWebinars.filter((item) => product.webinars.includes(item.webinar_name));
			//Собрать все sales в один массив
			const sales: SaleWeb[] = [];
			websRevenue.forEach((item) => {
				sales.push(...item.sales);
			});

			//Если продукты с одиаковыми ID, то суммируем продажи и выручку
			const salesNew: SaleWeb[] = [];

			sales.forEach((item) => {
				const existingSaleIndex = salesNew.findIndex(sale => sale.product_id === item.product_id);

				if (existingSaleIndex !== -1) {
					salesNew[existingSaleIndex].sales += item.sales;
					salesNew[existingSaleIndex].revenue += item.revenue;
				} else {
					salesNew.push(item);
				}
			});

			spendByProductNew.push({
				name: product.productName,
				spend: spend.reduce((acc, item) => acc + item.total, 0),
				registration,
				checkins,
				sales: salesNew
			});
		});

		setStatsByProduct(spendByProductNew);
	}, [uniqProducts, store.dateStore.startDate, store.dateStore.endDate, spendData, urrlByProduct]);

	useEffect(() => {
		const productsMap: { [key: string]: {
			productName: string;
			urls: string[];
			webinars: string[];
	} } = {};

		// Добавляем URL в productsMap
		urrlByProduct.forEach((url) => {
			url.product_name.split(',').map(item => item.trim()).forEach((productName) => {
				if (!productsMap[productName]) {
					productsMap[productName] = { productName, urls: [], webinars: [] };
				}
				productsMap[productName].urls.push(url.url);
			});
		});

		// Добавляем Webinars в productsMap
		webinarsByProduct.forEach((webinar) => {
			webinar.product_name.split(',').map(item => item.trim()).forEach((productName) => {
				if (!productsMap[productName]) {
					productsMap[productName] = { productName, urls: [], webinars: [] };
				}
				productsMap[productName].webinars.push(webinar.webinar_name);
			});
		});

		// Убираем дубликаты и конвертируем в массив
		const productsAll: {
			productName: string;
			urls: string[];
			webinars: string[];
	}[] = Object.values(productsMap).map(product => {
		product.urls = Array.from(new Set(product.urls));
		product.webinars = Array.from(new Set(product.webinars));
		return product;
	});

		setUniqProducts(productsAll);
	}, [urrlByProduct, webinarsByProduct]);

	const replaceCountryName = (data: CountryInfo[], countriesReplace: CountryReplace[]): CountryInfo[] => {
		let newData = data;

		countriesReplace.forEach((country) => {
			newData = newData.map((item) => {
				if (item.country === country.oldName) {
					item.country = country.newName;
				}
				return item;
			});
		});

		return newData;
	};

	const handleChangePageSpend = (event: unknown, newPage: number) => {
		setPageSpend(newPage);
	};

	const handleChangeRowsPerPageSpend = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageSpend(+event.target.value);
		setPageSpend(0);
	};

	const handleChangePageCountrtyFbTable = (event: unknown, newPage: number) => {
		setPageCountrtyFbTable(newPage);
	};

	const handleChangeRowsPerPageCountrtyFbTable = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPageCountrtyFbTable(+event.target.value);
		setPageCountrtyFbTable(0);
	};

	function getCountryName(code: string): string {
		const countryName = countries.getName(code, 'ru');
		if (!countryName) {
			return code;
		}
		return countryName;
	}

	const [sortConfigFbCountry, setSortConfigFbCountry] = useState<{ key: string, direction: string } | null>(null);

	const requestSortFbCountry = (key: string) => {
		let direction = 'ascending';
		if (sortConfigFbCountry && sortConfigFbCountry.key === key && sortConfigFbCountry.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfigFbCountry({ key, direction });

		const sortedArray = [...countrtyFbTable];

		sortedArray.sort((a, b) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			if (a[key] < b[key]) {
				return direction === 'ascending' ? -1 : 1;
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			if (a[key] > b[key]) {
				return direction === 'ascending' ? 1 : -1;
			}
			return 0;
		});

		setCountryFbTable(sortedArray);
	};

	const openCreative = (adId: string, accountId: string) => {
		window.open(`https://adsmanager.facebook.com/adsmanager/manage/ads/edit?selected_ad_ids=${adId}&act=${accountId}`);
	}

	
	return (
		<div style={{maxWidth: '100%'}}>

			<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: 40}}>
				<SearchUtm type='enter_utm_source' result={setUtmSource} value={utmSource} placeholder={'UTM Source'}/>
				<SearchUtm type='enter_utm_medium' result={setUtmMedium} value={utmMedium} placeholder={'UTM Medium'}/>
				<SearchUtm type='enter_utm_campaign' result={setUtmCampaign} value={utmCampaign} placeholder={'UTM Campaign'}/>
				<SearchUtm type='enter_utm_term' result={setUtmTerm} value={utmTerm} placeholder={'UTM Term'}/>
				<SearchUtm type='enter_utm_content' result={setUtmContent} value={utmContent} placeholder={'UTM Content'}/>
				<Button onClick={updateTrafficStats} appearance='auth' style={{width: 200}}>Обновить</Button>
			</div>


			<div style={{display: 'flex', flexWrap: 'wrap'}}>

				<Indicator 
					name='Потрачено' 
					curentValue={trafficStats?.expenses}
					format='currency' 
					symbol='$'
				/>

				{trafficStats &&

				<>

					<Indicator 
						name='Новых контактов' 
						curentValue={trafficStats.conatcts_count}
						format='number'
					/>

					<Indicator 
						name='Цена за контакт' 
						curentValue={trafficStats?.expenses / trafficStats.conatcts_count}
						format='currency' 
						symbol='$'
					/>

					<Indicator 
						name='Регистраций' 
						curentValue={trafficStats.webinar_registrations}
						format='number'
					/>

					<Indicator 
						name='CPL' 
						curentValue={trafficStats?.expenses / trafficStats.webinar_registrations}
						format='currency' 
						symbol='$'
					/>

					<Indicator 
						name='Чекинов' 
						curentValue={trafficStats.webinar_checkins}
						format='number'
					/>

					<Indicator 
						name='% в чекин' 
						curentValue={+(trafficStats.webinar_checkins / trafficStats.webinar_registrations * 100).toFixed(2)}
						format='percent'
					/>

					<Indicator 
						name='Броней' 
						curentValue={trafficStats.bron_count}
						format='number'
					/>

					<Indicator 
						name='Цена брони' 
						curentValue={trafficStats?.expenses / trafficStats.bron_count}
						format='currency' 
						symbol='$'
					/>

					<Indicator 
						name='Выручка' 
						curentValue={trafficStats.revenues}
						format='currency' 
						symbol='$'
					/>

					<Indicator 
						name='Продаж' 
						curentValue={trafficStats.sales}
						format='number'
					/>

					<Indicator 
						name='Средний чек' 
						curentValue={trafficStats.revenues / trafficStats.sales}
						format='currency' 
						symbol='$'
					/>

					<Indicator 
						name='ROAS' 
						curentValue={+(trafficStats.revenues / trafficStats?.expenses * 100).toFixed(2)}
						format='percent'
					/>

				</>

				}

				<div style={{width: '100%', height: 1, background: 'rgb(224, 224, 224)', marginTop: 20, marginBottom: 20}}/>

				<div>

					<h3 style={{marginBottom: 10}}>С учетом ранее зарегистрированных</h3>

					{trafficStats &&

						<div style={{display: 'flex', flexWrap: 'wrap'}}>
							<Indicator 
								name='Броней' 
								curentValue={trafficStats.bron_count_with_old}
								format='number'
							/>

							<Indicator 
								name='Цена брони' 
								curentValue={trafficStats?.expenses / trafficStats.bron_count_with_old}
								format='currency' 
								symbol='$'
							/>

							<Indicator 
								name='Выручка' 
								curentValue={trafficStats.revenues_with_old}
								format='currency' 
								symbol='$'
							/>

							<Indicator 
								name='Продаж' 
								curentValue={trafficStats.sales_with_old}
								format='number'
							/>

							<Indicator 
								name='Средний чек' 
								curentValue={trafficStats.revenues_with_old / trafficStats.sales_with_old}
								format='currency' 
								symbol='$'
							/>

						</div>

					}

				</div>

			</div>

			<div>

				{statsByProduct && statsByProduct.map((product) => (
					<div>
						<div style={{width: '100%', height: 1, background: 'rgb(224, 224, 224)', marginTop: 20, marginBottom: 20}}/> 
						<h3 style={{marginBottom: 10}}>{product.name}</h3>
						<div style={{display: 'flex', flexWrap: 'wrap'}}>

							<Indicator 
								name="Расход"
								curentValue={product.spend}
								format='currency' 
								symbol='$'
							/>

							{product.registration > 0 &&
							<Indicator 
								name='Регистрации' 
								curentValue={product.registration}
								format='number'
							/>
							}

							{product.registration > 0 && product.spend > 0 &&
							<Indicator 
								name='CPL' 
								curentValue={product.spend / product.registration}
								format='currency' 
								symbol='$'
							/>
							}

							{product.checkins > 0 &&
							<Indicator 
								name='Чекинов' 
								curentValue={product.checkins}
								format='number'
							/>
							}

							{product.checkins > 0 && product.registration > 0 &&
							<Indicator 
								name='% в чекин' 
								curentValue={+(product.checkins / product.registration * 100).toFixed(2)}
								format='percent'
							/>
							}

							{product.sales && product.sales.length > 0 &&
							product.sales.map((sale) => (
								<>
									<Indicator 
										name={`${sale.product_name}`} 
										curentValue={sale.sales}
										format='number'
									/>

									<Indicator 
										name={`$ брони ${sale.product_name}`} 
										curentValue={product.spend / sale.sales}
										format='currency' 
										symbol='$'
									/>

									<Indicator 
										name={`CV ${sale.product_name}`}
										curentValue={+(sale.sales / product.registration * 100).toFixed(2)}
										format='percent'
									/>
								</>
							))
							}

						</div>
					</div>
				))}


			</div>


			<div style={{display: 'flex', flexWrap: 'wrap', marginTop: 20, justifyContent: 'center'}}>


				<div style={{ width: '100%', maxWidth: 800, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 800, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Ссылка</TableCell>
										<TableCell align="right">Расход</TableCell>
										<TableCell align="center">Продукт</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{spendData && spendData.slice(pageSpend * rowsPerPageSpend, pageSpend * rowsPerPageSpend + rowsPerPageSpend)
										.map((row) => (
											<TableRow
												key={row.url}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">
													{row.url}
												</TableCell>
												<TableCell align="right">{row.total ? `${formatNumberWithSpaces(row.total.toFixed(2))} $`: 0}</TableCell>
												<TableCell align="center">
													{urrlByProduct.find((item) => item.url === row.url)?.product_name}
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={spendData ? spendData.length : 0}
							rowsPerPage={rowsPerPageSpend}
							page={pageSpend}
							onPageChange={handleChangePageSpend}
							onRowsPerPageChange={handleChangeRowsPerPageSpend}
						/>
					</Paper>

				</div>

				<div style={{ width: '100%', maxWidth: 800, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 800, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Страна</TableCell>
										<TableCell  onClick={() => requestSortFbCountry('spent')} style={{cursor: 'pointer'}} align="right">Расход</TableCell>
										<TableCell onClick={() => requestSortFbCountry('revenue')} style={{cursor: 'pointer'}} align="right">Выручка</TableCell>
										<TableCell  onClick={() => requestSortFbCountry('sales')} align="right">Продаж</TableCell>
										<TableCell onClick={() => requestSortFbCountry('roas')} style={{cursor: 'pointer'}} align="right">ROAS</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{countrtyFbTable && countrtyFbTable.slice(pageCountrtyFbTable * rowsPerPageCountrtyFbTable, pageCountrtyFbTable * rowsPerPageCountrtyFbTable + rowsPerPageCountrtyFbTable)
										.map((row) => (
											<TableRow
												key={row.country}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">
													{row.country}
												</TableCell>
												<TableCell align="right">{row.spent ? `${formatNumberWithSpaces(row.spent.toFixed(2))} $`: '0 $'}</TableCell>
												<TableCell align="right">{row.revenue ? `${formatNumberWithSpaces(row.revenue.toFixed(2))} $`: '0 $'}</TableCell>
												<TableCell align="right">{row.sales}</TableCell>
												<TableCell align="right">{row.roas ? `${row.roas} %`: '0 %'}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={countrtyFbTable ? countrtyFbTable.length : 0}
							rowsPerPage={rowsPerPageCountrtyFbTable}
							page={pageCountrtyFbTable}
							onPageChange={handleChangePageCountrtyFbTable}
							onRowsPerPageChange={handleChangeRowsPerPageCountrtyFbTable}
						/>
					</Paper>

					<div style={{display: 'flex', flexWrap: 'wrap'}}>
						<Indicator 
							name='Потрачено' 
							curentValue={totalSpentCountry}
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='Выручка' 
							curentValue={totalRevenueCountry}
							format='currency' 
							symbol='$'
						/>

						<Indicator 
							name='ROAS' 
							curentValue={+(totalRevenueCountry / totalSpentCountry * 100).toFixed(2)}
							format='percent' 
							symbol='%'
						/>
					</div>

				</div>

				<div style={{ width: '100%', maxWidth: 800, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 800, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>AD ID</TableCell>
										<TableCell  onClick={() => sortByKeyCreativesTable('expenses')} style={{cursor: 'pointer'}} align="right">Расход</TableCell>
										<TableCell onClick={() => sortByKeyCreativesTable('revenue')} style={{cursor: 'pointer'}} align="right">Выручка</TableCell>
										<TableCell  onClick={() => sortByKeyCreativesTable('sales_count')} align="right">Продаж</TableCell>
										<TableCell onClick={() => sortByKeyCreativesTable('roas')} style={{cursor: 'pointer'}} align="right">ROAS</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{creativesTable && creativesTable.slice(pageCreativesTable * rowsPerPageCreativesTable, pageCreativesTable * rowsPerPageCreativesTable + rowsPerPageCreativesTable)
										.map((row) => (
											<TableRow
												key={row.ad_id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row" onClick={() => openCreative(row.ad_id, row.account_id)} style={{cursor: 'pointer'}}>
													{row.ad_id}
												</TableCell>
												<TableCell align="right">{row.expenses ? `${formatNumberWithSpaces(row.expenses.toFixed(2))} $`: '0 $'}</TableCell>
												<TableCell align="right">{row.revenue ? `${formatNumberWithSpaces(row.revenue.toFixed(2))} $`: '0 $'}</TableCell>
												<TableCell align="right">{row.sales_count}</TableCell>
												<TableCell align="right">{row.roas ? `${row.roas} %`: '0 %'}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={creativesTable ? creativesTable.length : 0}
							rowsPerPage={rowsPerPageCreativesTable}
							page={pageCreativesTable}
							onPageChange={handleChangePageCreativesTable}
							onRowsPerPageChange={handleChangeRowsPerPageCreativesTable}
						/>
					</Paper>


				</div>


			</div>


		</div>
	)
})

export default FB